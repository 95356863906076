import { useEstimatedPrice } from '#app/hooks/use-estimated-price'

export const EstimatedPrice = ({
	objectSize,
	active,
}: {
	objectSize: string
	active: boolean
}) => {
	const query = useEstimatedPrice(objectSize)
	const estimate =
		Number(query.data?.price ?? 0) + Number(query.data?.fixedFee ?? 0)
	return (
		<div className="inline-flex flex-1 flex-shrink-0 flex-col items-center justify-center gap-[9px] lg:items-end lg:pr-[18px]">
			<span className="whitespace-nowrap font-medium leading-relaxed text-slate-700 lg:text-right lg:text-xs lg:leading-tight">
				Cena
			</span>
			<span className="text-right text-xl font-medium leading-relaxed text-rose-400">
				{query.data ? (active ? estimate : 0) : '---'} Kč
			</span>
		</div>
	)
}
