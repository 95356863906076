import { type ActionFunctionArgs } from '@remix-run/node'
import { useNavigation } from '@remix-run/react'
import { addHours, setSeconds } from 'date-fns'
import { makeDomainFunction } from 'domain-functions'
import { useMemo } from 'react'
import { twMerge } from 'tailwind-merge'
import { z } from 'zod'
import { Form } from '#app/components/forms'
import { Button } from '#app/components/ui/button.tsx'
import { Command, CommandPrimitive } from '#app/components/ui/command'
import { api } from '#app/utils/api'
import { formAction } from '#app/utils/form-action.server'
import { cn, parseValuesIntoSearch } from '#app/utils/misc'
import { AddressAutocomplete } from '../../../components/address-autocomplete'
import { EstimatedPrice } from './estimated-price'

export const schema = z
	.object({
		address: z.string().default(''),
		date: z.date({
			invalid_type_error: '*Povinné',
			required_error: '*Povinné',
		}),
		time: z.string(),
		objectSize: z
			.enum(['small', 'medium', 'large'], {
				errorMap: () => ({ message: '*Povinné' }),
			})
			.default('small'),
	})
	.superRefine(async (data, ctx) => {
		const { data: addresses } = await api.searchAddresses(data.address)
		if ('errors' in addresses) return
		if (!addresses.length || addresses[0].value !== data.address) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				path: ['address'],
				message: '*Adresa musí být v Praze',
			})
			return z.NEVER
		}
	})

const mutation = makeDomainFunction(schema)(values => values)

export const action = ({ request }: ActionFunctionArgs) =>
	formAction({
		request,
		mutation,
		schema,
		successPath: values => {
			const url = new URL(request.url)
			url.pathname = '/dashboard/order-1'
			url.search = parseValuesIntoSearch(values, url.searchParams).toString()
			return url.toString()
		},
	})

export const CleanOrderForm = () => {
	const now = useMemo(() => {
		const now = new Date()
		now.setSeconds(0)
		now.setMinutes(Math.ceil(now.getMinutes() / 15) * 15)
		now.setHours(now.getHours() + 3)
		return now
	}, [])

	const navigation = useNavigation()

	return (
		<Form
			schema={schema}
			buttonComponent={Button as any}
			className="-mt-8 flex flex-col items-stretch gap-2 rounded-[10px] px-6 py-3 lg:flex-row lg:items-center lg:border lg:border-[#36415B4D] lg:bg-white"
			values={{ date: now, time: now.toLocaleTimeString() }}
		>
			{({ Field, Errors, Button, formState, watch, register, setValue }) => {
				return (
					<>
						<Field
							name="address"
							label="Kde chcete uklidit?"
							className={twMerge(
								'relative inline-flex flex-col items-center justify-center gap-1 rounded-lg border border-indigo-50 bg-white p-2.5 shadow transition-all lg:w-[14rem] lg:border-none lg:shadow-none',
								formState.errors.address &&
									'outline outline-2 outline-red-700 lg:pt-5',
							)}
						>
							{({ Label, Errors }) => (
								<>
									<Label className="w-full text-center text-sm font-medium leading-tight text-slate-700 lg:text-left" />
									<Command>
										<CommandPrimitive.Input
											className={cn(
												'w-full text-center text-sm leading-tight text-slate-700 text-opacity-75 outline-none max-lg:px-4 lg:text-left',
											)}
											{...register('address')}
											value={watch('address')}
											onValueChange={x => setValue('address', x)}
											placeholder="Vaše adresa"
										/>
										<AddressAutocomplete
											address={watch('address')}
											onAutocomplete={x => setValue('address', x)}
											className="top-16"
										/>
									</Command>
									<Errors className="absolute left-[6px] top-[7px] text-center text-xs font-semibold leading-none text-red-700" />
								</>
							)}
						</Field>

						<Field
							name="date"
							label="Který den?"
							placeholder="Datum úklidu"
							className={twMerge(
								'relative inline-flex flex-col items-center justify-center gap-1 rounded-lg border border-indigo-50 bg-white p-2.5 shadow transition-all lg:w-[14rem] lg:border-none lg:shadow-none',
								formState.errors.date &&
									'outline outline-2 outline-red-700 lg:pt-5',
							)}
						>
							{({ Label, Errors, SmartInput, errors }) => (
								<>
									<Label className="w-full text-center text-sm font-medium leading-tight text-slate-700 lg:text-left" />
									<SmartInput
										className={twMerge(
											'mx-1 w-full text-center text-sm leading-tight text-slate-700 text-opacity-75 outline-none max-lg:px-4 max-sm:min-w-full max-sm:rounded-full lg:text-left',
											errors?.length && 'text-red-700 placeholder-red-700',
										)}
									/>
									<Errors className="absolute left-[6px] top-[7px] text-center text-xs font-semibold leading-none text-red-700" />
								</>
							)}
						</Field>
						<Field
							name="time"
							label="V kolik hodin?"
							placeholder="Čas úklidu"
							className={twMerge(
								'relative inline-flex flex-col items-center justify-center gap-1 rounded-lg border border-indigo-50 bg-white p-2.5 shadow transition-all lg:w-[14rem] lg:border-none lg:shadow-none',
								formState.errors.time &&
									'outline outline-2 outline-red-700 lg:pt-5',
							)}
						>
							{({ Label, Errors, SmartInput, errors }) => (
								<>
									<Label className="w-full text-center text-sm font-medium leading-tight text-slate-700 lg:text-left" />
									<SmartInput
										type="time"
										className={twMerge(
											'mx-1 w-full text-center text-sm leading-tight text-slate-700 text-opacity-75 outline-none max-lg:px-4 max-sm:min-w-full max-sm:rounded-full lg:text-left',
											errors?.length && 'text-red-700 placeholder-red-700',
										)}
									/>
									<Errors className="absolute left-[6px] top-[7px] text-center text-xs font-semibold leading-none text-red-700" />
								</>
							)}
						</Field>
						<Field
							name="objectSize"
							label="Jak velký je prostor?"
							placeholder="Velikost prostoru"
							className={twMerge(
								'relative inline-flex flex-col items-center justify-center gap-1 rounded-lg border border-indigo-50 bg-white p-2.5 shadow transition-all lg:w-[14rem] lg:border-none lg:shadow-none',
								formState.errors.objectSize &&
									'outline outline-2 outline-red-700 lg:pt-5',
							)}
						>
							{({ Label, Errors, SmartInput, errors }) => (
								<>
									<Label className="w-full text-center text-sm font-medium leading-tight text-slate-700 lg:text-left" />
									<SmartInput
										className={
											'text-align-last-center w-full text-center text-sm leading-tight text-slate-700 text-opacity-75 outline-none max-lg:px-4 lg:text-left' +
											(errors?.length ? 'placeholder-red-700' : '')
										}
										options={[
											{ name: 'Malý (0m²-50m²)', value: 'small' },
											{ name: 'Střední (50m²-75m²)', value: 'medium' },
											{ name: 'Velký (+75m²)', value: 'large' },
										]}
										registerProps={register('objectSize')}
										placeholder="Velikost prostoru"
									/>
									<Errors className="absolute left-[6px] top-[7px] text-center text-xs font-semibold leading-none text-red-700" />
								</>
							)}
						</Field>

						<EstimatedPrice
							objectSize={watch('objectSize')}
							active={!!watch('address').length}
						/>

						<Button className="h-14 min-w-[140px]">
							{navigation.state === 'submitting' ||
							(navigation.state === 'loading' &&
								navigation.location.pathname === '/dashboard/order-1')
								? 'Jdeme na to...'
								: 'Kalkulace'}
						</Button>
						<Errors />
					</>
				)
			}}
		</Form>
	)
}
